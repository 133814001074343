import React from 'react';
import Carousel from 'nuka-carousel';
import SliderItem from './SliderItem';

class Slider extends React.PureComponent {
  constructor (props) {
    super(props);
  }
  render () {
    const { sliderData } = this.props;
    return (
      <div className="mod-slider">
        {
          sliderData.length > 1 ?
          <Carousel dragging={true} autoplay={ true } autoplayInterval={ 3000 } wrapAround={ true }>
            {
              sliderData.map((item, index) => {
                return (
                  <SliderItem 
                    key={`slider-${index}`}
                    data={item}
                  />
                )
              })
            }
          </Carousel>
          :
          <SliderItem 
            data={sliderData[0]}
          />
        }
      </div>
    )
  }
}

export default Slider;