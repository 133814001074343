import request from '@/utils/request';

export async function getCmsConfig(data) {
  return request('http://qf-bos.xuanwonainiu.com/h5/active-page', {
    method: 'POST',
    data,
  });
}

export async function getProdList(data) {
  return request('http://restapi.xuanwonainiu.com/product/cms/list/search', {
    method: 'POST',
    data,
  });
}