import React from 'react';
import { connect } from 'dva';
import './index.less';
import LoadMore from '@/components/LoadMore';
import Product from '@/components/ProductList';

@connect(({ cmsModel, loading }) => ({
  prodList: cmsModel.prodList,
  page: cmsModel.prodList.page,
  loading: loading.effects['cmsModel/getCmsProd'],
  cmsModel,
}))
class CmsProList extends React.PureComponent {
  componentDidMount () {
    const {
      dispatch
    } = this.props;
    dispatch({
      type: 'cmsModel/initProd',
    });
    this.handleLoading(1);
  }
  handleLoading = (isInit) => {
    const {
      component: {
        jsonContent
      },
      dispatch,
      loading,
    } = this.props;
    const params = {
      salesQuantityMin: 99,
      page: isInit ? 0 : this.props.page + 1,
      productType: 0,
      size: 10,
      sortRule: jsonContent.sorts,
      sources: jsonContent.dataProSources.map(r => r * 1),
    }
    dispatch({
      type: 'cmsModel/getCmsProd',
      payload: params,
    })
  }
  render () {
    const {
      component,
      prodList,
      loading,
    } = this.props;
    let { type, styleName } = component;
    const typeLower = type.toLowerCase();
    const loadingMethod = {
      loading,
      handleLoading: this.handleLoading
    }

    return (
      <section className={`cms-panel panel-${typeLower} style-${styleName}`}>
        <LoadMore 
          {...loadingMethod}
        >
          <Product
            prodList={prodList}
          />
        </LoadMore>
      </section>
    );
  }
}

export default CmsProList;
