import React, { Component } from 'react';
import { connect } from 'dva';
import { Helmet } from 'react-helmet';
import './index.less';
import Loading from '@/components/Loading';
import LoadMore from '@/components/LoadMore';

import List from './Components/List';
import Pop from './Components/Pop';


@connect(({ loading, searchModel }) => ({
  list: searchModel.list,
  loading: loading.effects['searchModel/query'],
  pwd: searchModel.pwd,
}))
class SearchPage extends Component {
  constructor(props) {
    super(props);
    const {
      location: {
        query,
      },
    } = this.props;
    this.state = {
      realKeyword: query.searchText,
      keyword: query.searchText,
      page: 0,
      sort: query.sortSuper || '',
    }
  }

  componentDidMount () {
    this.fetchList(1);
  }

  fetchList = isInt => {
    const {
      dispatch,
    } = this.props;
    if (!this.state.realKeyword) return;
    const params = {
      keyword: this.state.realKeyword,
      sourcePlatType: 'TB',
      page: isInt ? 0 : this.state.page,
      sort: this.state.sort,
    }
    this.setState({
      page: isInt ? 1 : this.state.page + 1,
    });
    dispatch({
      type: 'searchModel/query',
      payload: params,
    })
  }

  changeValue = e => {
    this.setState({
      keyword: e.target.value,
    })
  }

  doSearch = e => {
    this.setState({
      realKeyword: this.state.keyword,
    }, () => {
      this.fetchList(1);
    })
    e.preventDefault()
  }

  render () {
    const {
      list,
      loading,
      location: {
        query,
      },
      pwd,
    } = this.props;
    const loadingMethod = {
      loading,
      handleLoading: this.fetchList,
    }
    return (
      <>
        <Helmet>
          <title>{(this.props.location.query.searchText || '')}的搜索结果</title>
        </Helmet>
        <form className="search-wrap flex items-center" action="">
          <span className="newXw">&#xe61e;</span>
          <input type="search" className="search-ipt" placeholder="输入商品名称"
            value={this.state.keyword}
          onChange={this.changeValue} />
          <button className="search-btn" onClick={this.doSearch}>搜索</button>
        </form>
        <LoadMore
          {...loadingMethod}
        >
          <List list={list} query={query} />
        </LoadMore>
        <Pop pwd={pwd}/>
      </>
    );
  }
}

export default SearchPage;
