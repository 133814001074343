import React from 'react';
import './index.less';
import Item from './Item';

const List = props => {
  const { list, query } = props;
  return (
    <div className="searchList">
      {
        list.map(item => <Item query={query} key={item.itemId} item={item} />)
      } 
    </div>
  );
};

export default List;
