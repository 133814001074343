import React from 'react';
import { connect } from 'dva';
import CopyWrap from '@/components/CopyWrap';

import './pop.less';

@connect(({ }) => ({
  
}))
class Pop extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  handleClose = () => {
    this.props.dispatch({
      type: 'searchModel/savePwd',
      payload: '',
    })
  }
  render () {
    const {
      pwd
    } = this.props;
    return (
      <>
        {pwd ? <section className="pwdPop">
        <div className="popMain">
          <div className="pwdWrap">
            点击下方按钮一键复娡口令
          </div>
          <p className="pwd">
            {pwd}
          </p>
          <CopyWrap
            handle="#popCopy"
            text={pwd}
            onSuccess={() => {
              window.alertTip('复娡成功，请打开手机tb');
              this.handleClose();
            }}
            onFail={
              () => {
                window.alertTip('复娡失败，请长按手动复娡');
              }
            }
          >
            <button id="popCopy" className="btn btn-red" data-clipboard-text={pwd}>一键复娡</button>
          </CopyWrap>
          <div className="close" onClick={this.handleClose}>
            关闭
          </div>
        </div>
      </section> : ''}
      </>
    );
  }
}

export default Pop;
