import {
  parse
} from 'querystring';

export const remainParmas = ['isShowCom', 'domain', 'nav', 'p', 'dl', 'agentId', 'jdP', 'pddP', 'pid', 'rid'];

import qs from 'qs';
/* eslint no-useless-escape:0 import/prefer-default-export:0 */

const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = path => reg.test(path);

export const getPageQuery = () => parse(window.location.href.split('?')[1]);



export const sleep = time => new Promise((resolve, reject) => {
  setTimeout(resolve, time);
})

export function getUrlQuery() {
  const {
    search,
    hash
  } = window.location;
  let searchStr = search || hash;
  searchStr = searchStr.replace(/^(.*?\?)/, '');

  return qs.parse(searchStr);
}

export function concatUrlAndQuery(baseUrl, query) {
  const currQuery = getUrlQuery();

  if (query) {
    // 带上自定义pid
    query.aliPid = currQuery.aliPid;
    query.pddPid = currQuery.pddPid;
  }
  if (typeof query === 'object') {
    query = qs.stringify(query);
  } else if (typeof query !== 'string' || !query) {
    return baseUrl;
  }
  // 对拼多多页面地址进行转换
  const {
    matched,
    link
  } = cmsPddLinkTransform(baseUrl, currQuery.pddPid, currQuery.acId);
  if (matched) {
    return link;
  }

  if (/\?/.test(baseUrl)) {
    baseUrl += '&';
  } else {
    baseUrl += '?';
  }
  if (baseUrl.indexOf('dl.xuanwonainiu') > 0) {
    return baseUrl;
  } else {
    return baseUrl + query;
  }
}

export function cmsPddLinkTransform (link, pddPid = '', acId = '') {
  let ctype = -1;
  let pid = pddPid;
  const match = link.match(/https?:\/\/mobile\.yangkeduo\.com\/(.*?)\?(.*)$/);
  if (match && match.length >= 2) {
    const pathname = match[1];
    const query = qs.parse(match[2]);
    if (!pid) {
      pid = query.pid;
    }

    switch (pathname) {
      case 'duo_nine_nine.html': {
        ctype = 0;
        break;
      }
      case 'duo_today_burst.html': {
        ctype = 1;
        break;
      }
      case 'duo_transfer_channel.html': {
        if (query.resourceType === '4') {
          ctype = 8;
        } else if (query.resourceType === '39997') {
          ctype = 9;
        }
        break;
      }
      case 'duo_red_packet.html': {
        ctype = 6;
        break;
      }
    }
  }

  if (ctype >= 0 && pid) {
    // 做转换
    return {
      link: `http://restapi.en0s.cn/product/pdd/T/${pid}/${ctype}?customeParams=${acId}`,
      matched: true,
    };
  }

  return {
    link,
    matched: false
  };
}

export function cmsLinkTo(linkType, objectId, targetUrl) {
  let targetQuery = getTargetQuery();
  let urlTarget;
  switch (linkType) {
    case 'PAGE': {
      urlTarget = concatUrlAndQuery(`${window.location.origin}/c?pageId=${objectId}`, targetQuery);
      break;
    }
    case 'PRODUCTS': {
      urlTarget = concatUrlAndQuery(`${window.location.origin}/product-detail?es=true&id=${objectId}`, targetQuery);
      break;
    }
    default: {
      urlTarget = concatUrlAndQuery(targetUrl, targetQuery);
    }
  }

  if (objectId || targetUrl) {
    window.location.href = urlTarget;
  }
}

export function getTargetQuery(url = '') {
  let targetQuery = {};
  let search = '';
  let _url = url.indexOf('?') > -1 ? url : url + '?';
  search = _url.split('?')[1];
  const urlQ = qs.parse(search);
  const urlQuery = url ? urlQ : getUrlQuery();
  remainParmas.forEach(param => {
    urlQuery[param] !== void 0 && (targetQuery[param] = urlQuery[param]);
  });

  return targetQuery;
}
