import request from '@/utils/request';

export async function getProdDetail(data) {
  return request('/product/mall/promote', {
    method: 'POST',
    data,
  });
}

export async function getProdDetailAgent(data) {
  return request('/product/agent/promote/new', {
    method: 'POST',
    data,
  });
}

export async function getProdList(data) {
  return request('http://restapi.xuanwonainiu.com/product/cms/list/search', {
    method: 'POST',
    data,
  });
}