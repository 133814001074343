import React from 'react';
import Link from '@/components/Link';

const Item = props => {
  const { item } = props;
  const {
    id,
    imageUrl,
    couponPrice,
    startfee,
    promotionPrice,
    salesPrice,
  } = item;
  const isManJian = startfee && couponPrice &&  startfee > salesPrice;
  const url = `/detail?id=${id}`;
  return (
    <li className="product-item">
      <li className="product-item" key={id}>
        <Link to={url}>
          <div className="product-img">
           <img src={imageUrl} alt="" />
          </div>
          <div className="product-info">
            <div className="product-title">
              <span>{item.prodName}</span>
            </div>
            <div className="product-data flex items-center justify-between">
              {
                couponPrice && couponPrice > 0 ? 
                <span className="coupon">券:{couponPrice}元</span> : ''
              }
              {
                isManJian ? <p className="coupon-info">满{startfee}减{couponPrice}</p> : ''
              }
            </div>
            <div className="product-bottom">
              <div className="product-sales-price flex items-end">
                券后<div className="price price-cny">{promotionPrice}</div>
                <div className="product-price-old price price-cny">{salesPrice}</div>
              </div>
            </div>
          </div>
        </Link>
      </li>
    </li>
  );
};

export default Item;
