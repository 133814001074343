import React, { Component } from 'react';
import { connect } from 'dva';
import { Helmet } from 'react-helmet';
import './index.less';
import Loading from '@/components/Loading';
import Pop from './Components/Pop';
@connect(({ loading, collectionModel, searchModel }) => ({
  list: collectionModel.list,
  pwd: searchModel.pwd,
  loading: loading.effects['collectionModel/query'],
  loadingPwd: loading.effects['searchModel/getPwd'],
}))
class SearchPage extends Component {
  constructor(props) {
    super(props);
    const {
      location: {
        query: {
          ids,
          agentId,
        },
      },
    } = this.props;
    this.state = {
      ids,
      agentId,
    }
  }

  componentDidMount () {
    this.fetchList();
  }

  fetchList = () => {
    const {
      dispatch,
    } = this.props;
    const params = {
      ids: this.state.ids,
    }
    dispatch({
      type: 'collectionModel/query',
      payload: params,
    })
  }

  getPwd = item => {
    const {
      itemId,
      couponId,
    } = item;
    this.props.dispatch({
      type: 'searchModel/getPwd',
      payload: {
        agentId: this.state.agentId,
        itemId,
        activityId: couponId,
      },
    })
  }

  render () {
    const {
      list,
      loading,
      loadingPwd,
      pwd,
    } = this.props;
    return (
      <>
        <Helmet>
          <title>精选商品</title>
        </Helmet>
        {
          loadingPwd || loading ? <Loading /> : ''
        }
        <div className="product-list product-list-2">
        <ul>
          {
            list.map(item => <li onClick={() => { this.getPwd(item) }} className="product-item" key={item.commissionId}>
                          <div className="product-img">
                          <img src={item.imageUrl} alt="" />
                          </div>
                          <div className="product-info">
                            <div className="product-title">
                              <span>{item.itemTitle}</span>
                            </div>
                            <div className="product-data flex items-center justify-between">
                            </div>
                            <div className="product-bottom">
                              <div className="product-sales-price flex items-end">
                                券后<div className="price price-cny">{item.promotionPrice}</div>
                                <div className="product-price-old price price-cny">{item.salesPrice}</div>
                              </div>
                            </div>
                          </div>
                    </li>)
          }
        </ul>
        </div>
        <Pop pwd={pwd}/>
      </>
    );
  }
}

export default SearchPage;
