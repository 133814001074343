import React from 'react';
import LazyLoad from 'react-lazyload';
import { connect } from 'dva';
import './index.less';

function getIfManjian (item) {
  if (!item.coupon.length) return false;
  const {
    quota,
  } = item.coupon[0];
  const {
    price,
  } = item;
  return quota > price;
}
function getCouponText (item) {
  const {
    quota,
    amount,
  } = item.coupon[0];
  if (getIfManjian(item)) {
    return `满${quota}-${amount},需凑单`
  }
  return `￥${amount}`;
}

@connect(({ loading }) => ({
  loading: loading.effects['searchModel/getPwd'],
}))
class Item extends React.PureComponent {
  handleGetPwd = item => {
    this.props.dispatch({
      type: 'searchModel/getPwd',
      payload: {
        agentId: this.props.query.agentId || 8966,
        itemId: item.itemId,
      },
    })
  }

  render () {
    const { item, loading } = this.props;
    return (
      <div className="item">
        <div className="item-wrap flex">
          <div className="img">
            <LazyLoad >
              <img src={item.img} alt="" />
            </LazyLoad>
          </div>
          <div className="info">
            <h3 className="titile">
              <span
                className="newXw platform platform-tb">&#xe615;</span>
              {item.title}
            </h3>
            <p className="shop" v-if="item.shopName">
              <span className="newXw">&#xe670;</span>
              {item.shopName}</p>
            <div className="tag-wrap flex">
              {item.coupon.length ? <div className="coupon">
                券{getCouponText(item)}
              </div> : ''}
            </div>
            <div className="price">
              {item.coupon.length ? <span className="prefix">券后</span> : ''}
              <span className="money"><span>￥</span>{item.promotionPrice}</span>
              {item.coupon.length ? <del className="suffix">￥{item.price}</del> : ''}
            </div>
            <div className="sell" v-if="item.sales">
              已售{item.sales}
            </div>
          </div>
        </div>
        <div className="buy-wrap flex justify-end">
          <div className="buy-btn" onClick={() => {
            this.handleGetPwd(item)
          }}>
            {loading ? '获取中，请稍后' : '获取叩苓'}
          </div>
        </div>
      </div>
    );
  }
}

export default Item;
