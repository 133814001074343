import React from 'react';

import { cmsLinkTo } from '@/utils/utils';
import "./index.less";

class CmsNav extends React.PureComponent {
  constructor (props) {
    super(props);
  }
  
  render () {
    const {
      component,
    } = this.props;

    const { type, styleName, jsonContent: { contents } } = component;

    const typeLower = type.toLowerCase();

    return (
      <section className={`cms-panel panel-${typeLower} style-${styleName}`}>
        <ul className="list-nav">
          {
            contents.map((item, index)=> {
              const { imgUrl, linkType, objectId, targetUrl } = item;
              return (
                <li key={`nav-${index}`}>
                  <a href="#" onClick={()=> {cmsLinkTo(linkType, objectId, targetUrl)}}>
                    {item.title}
                  </a>
                </li>
              )
            })
          }
        </ul>
      </section>
    );
  }
}

export default CmsNav;
