'use extensible';

import React from 'react';

import { cmsLinkTo } from '@/utils/utils';
import './index.less';

class CmsTitleLink extends React.PureComponent {
  constructor (props) {
    super(props);
  }

  render () {
    const {
      component,
    } = this.props;

    const {
      type, styleName, 
      jsonContent: { 
        enableBtnMore, enableImg, imgUrl, linkType, objectId, targetUrl, text
      } 
    } = component;

    const typeLower = type.toLowerCase();

    return (
      <section className={`cms-panel panel-${typeLower} style-${styleName}`} onClick={()=> {cmsLinkTo(linkType, objectId, targetUrl)}}>
        {
          enableImg ? 
            <img src={imgUrl} />
          : 
            <p> { text }</p>
        }
        
        { 
          enableBtnMore ?
            <span className="more">more</span>
          : ''
        }
      </section>
    );
  }
}

export default CmsTitleLink;
