import { getCollection } from './service'

const Model = {
  namespace: 'collectionModel',
  state: {
    list: [],
  },
  effects: {
    *query({ payload, callback }, { call, put, select }) {
      const res = yield call(getCollection, payload);
      if (res.status === 1) {
        yield put({
          type: 'save',
          payload: res.data
        })
      }
    },
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, list: payload };
    },
  },
};
export default Model;
