import { queryCurrent } from '@/services/user';

const UserModel = {
  namespace: 'user',
  state: {
    currentUser: {},
  },
  effects: {
    *fetchCurrent({ callback }, { call, put }) {
      const res = yield call(queryCurrent);
      const { status, data } = res;
      if (status === 1) {
        yield put({
          type: 'saveCurrentUser',
          payload: data,
        });
      }
      if (callback && typeof callback === 'function') {
        callback();
      }
    },
  },
  reducers: {
    saveCurrentUser(state, action) {
      const newState = { ...state, currentUser: action.payload || {} };
      return newState;
    },
  },
};
export default UserModel;
