import React from 'react';
import { connect } from 'dva';

class Layout extends React.Component {
  componentDidMount() {
  }

  render() {
    const { children } = this.props;
    return (
      <div className="container">
        { children }
      </div>
    );
  }
}

export default connect(({ loading }) => ({
}))(Layout);
