import React from 'react';
import { concatUrlAndQuery } from '@/utils/utils';

import { getTargetQuery } from '@/utils/utils';

class Link extends React.PureComponent {

  getRealLink = () => {
    const { to, query={} } = this.props;
    if (!to) {
      return 'javascript:;';
    }
    let targetQuery = {...query, ...getTargetQuery()};

    return concatUrlAndQuery(to, targetQuery);
  }

  render () {
    return (
      <a target="_self" {...this.props} href={this.getRealLink()} ></a>
    );
  }
}

export default Link;
