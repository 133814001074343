import request from '@/utils/request';
import { apiBaseProd } from '../../../config/custom';


export async function getSearch(params) {
  return request('/yh/commonSearch', {
    params,
  });
}

export async function getPwd(data) {
  return request(`${apiBaseProd}/newApp/promotion/1.0.0/tbPrivilegeAg`, {
    method: 'POST',
    data,
  });
}