import { getCmsConfig, getProdList } from './service'

const Model = {
  namespace: 'cmsModel',
  state: {
    pageConfig: {
      moduleList: [],
      moduleCount: 0,
    },
    prodList: {
      data: [],
      page: 0,
      size: 10,
      totalItem: 0,
      totalPage: 0,
      hasMore: true,
    },
  },
  effects: {
    *getCmsPage({ payload, callback }, { call, put }) {
      const res = yield call(getCmsConfig, {
        pageId: payload,
      });
      if (res.status === 1) {
        yield put({
          type: 'save',
          payload: res.data
        });
      }
    },
    *getCmsProd({ payload, callback }, { call, put }) {
      const res = yield call(getProdList, payload);
      if (res.status === 1) {
        yield put({
          type: 'saveList',
          payload: res.data
        });
      }
    },
    *initProd({ payload, callback }, { call, put }) {
      yield put({
        type: 'saveList',
        payload: {
          data: [],
          page: 0,
          size: 10,
          totalItem: 0,
          totalPage: 0,
        }
      });
    }
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, pageConfig: payload };
    },
    saveList(state, { payload }) {
      const oldAllRows = state.prodList.data;
      const newData = {
        ...payload,
        data: [ ...oldAllRows, ...payload.data ],
      }
      const hasMore = payload.data.length >= state.prodList.size;
      newData.hasMore = hasMore;
      return {
        ...state,
        prodList: newData,
      };
    }
  },
};
export default Model;
