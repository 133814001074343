import React, { Component } from 'react';
import { connect } from 'dva';
import { Helmet } from 'react-helmet';
import './index.less';
import ImageSlider from '@/components/ImageSlider';
import CopyWrap from '@/components/CopyWrap';
import Loading from '@/components/Loading';

@connect(({ loading, detailModel }) => ({
  detail: detailModel.data,
  loading: loading.effects[ 'detailModel/getDetail' ],
}))
class Home extends Component {
  componentDidMount () {
    const {
      location: {
        query
      }
    } = this.props;
    const params = {
      comId: query.id
    };
    if (query.agentId) {
      params.busObjectType = 'DAILI_GX_H5_SHARE';
      params.busSubType = 'DAILI_H5';
      params.agentId = query.agentId;
    } else if (query.p) {
      params.p = query.p;
    } else {
      params.p = 0;
    }
    this.props.dispatch({
      type: 'detailModel/getDetail',
      payload: params
    })
  }
  render () {
    const {
      detail,
      loading
    } = this.props;
    const {
      startfee,
      salesPrice,
      couponPrice,
      promotionPrice,
      taokePwd,
      prodName,
    } = detail;
    const isManJian = startfee && couponPrice && startfee > salesPrice;
    const textAreaProps = {
      defaultValue: `1 点击下方一键复制，打开淘领券购买\n或长按本段文字复制去绹靌\n${prodName}\n--------------------------\n${taokePwd}/`,
      onChange: () => {}
    };
    return (
      <>
        <Helmet>
          <title>{'有好货'}</title>
        </Helmet>
        {loading ? Loading : ''}
        {
          detail.prodName ? <section className="detail-wrap">
            <section className="banner-wrap">
              <ImageSlider images={detail.imageUrls || [ detail.imageUrl ] || []} />
            </section>
            <section className="info-wrap">
              <h2 className="name">
                {detail.prodName}
              </h2>
              <div className="info flex justify-between items-baseline flex-wrap">
                <div className="price-info flex items-end flex-wrap">
                  {
                    couponPrice ? <div className="coupon">券:{couponPrice}元</div> : ''
                  }
                  {
                    isManJian ? <p className="coupon-info">满{startfee}减{couponPrice}</p> : ''
                  }
                  <div className="flex items-baseline flex-wrap">
                    券后:<span className="price price-cny">{promotionPrice}</span>
                    <del className="product-price-old price price-cny">{salesPrice}</del>
                  </div>
                </div>

              </div>
            </section>
            <section className="tao-wrap">
              <div className="t-p-l-box-content fs14 flex items-center">
                <textarea {...textAreaProps} ref="copy_TaoToken_current"></textarea>
              </div>
              <p className="t-p-l-mark">1.复娡文案 &gt; 2.请打开桃宝APP</p>
              <section className={'btn-fixed flex btn-one'}>
                <CopyWrap
                  handle="#copy"
                  text={textAreaProps.defaultValue}
                  onSuccess={() => {
                    window.alertTip('复娡成功，请打开手机tb');
                  }}
                  onFail={
                    () => {
                      window.alertTip('复娡失败，请长按手动复娡');
                    }
                  }
                >
                  <button id="copy" className="btn btn-red btn-copy-token">一键复娡</button>
                </CopyWrap>
              </section>
            </section>

          </section> : ''
        }
      </>
    );
  }
}

export default Home;
