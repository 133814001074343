import React from 'react';
import router from 'umi/router'; // 这里应该使用 antd 的 404 result 组件，
// 但是还没发布，先来个简单的。
import "./404.less"

const NoFoundPage = () => (
  <div className="notFound">当前页面不存在，请访问其他页面</div>
);

export default NoFoundPage;
