import React from 'react';
import ProductItem from './ProductItem';
import './index.less';

const List = props => {
  const { prodList: { data }} = props;
  return (
    <div className="product-list product-list-2">
      <ul>
      {
      data.map(item => <ProductItem key={item.id} item={item} />)
    } 
    </ul>
    </div>
  );
};

export default List;
