import React from 'react';
import ClipboardJS from 'clipboard';

class CopyWrap extends React.PureComponent {
  constructor (props) {
    super(props);
  }
  componentDidMount() {
    this.clipboard = new ClipboardJS(this.props.handle, {
      text: () => this.props.text,
    });
    this.clipboard.on('success', this.props.onSuccess);
    
    this.clipboard.on('error', this.props.onFail);
  }
  componentWillUnmount() {
    this.clipboard.destroy();
  }
  render () {
    const { 
      children,
     } = this.props;
    return (
      <>
        {children}
      </>
    );
  }
}

export default CopyWrap;
