import React from 'react';
import './style.less';

class Loading extends React.PureComponent {
  render () {
    return (
      <div className="loaderWrap">
        <div className="ball-scale-multiple"><div></div><div></div><div></div></div>
      </div>
    );
  }
}

export default Loading;
