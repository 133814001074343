import React from 'react';

import Slider from './Slider';
import style from './index.less';

class CmsTemplate extends React.PureComponent {
  constructor (props) {
    super(props);
  }
  render () {
    const { component } = this.props;
    const { type, styleName, jsonContent: { contents } } = component;

    const typeLower = type.toLowerCase();

    return (
      <section className={`cms-panel panel-${typeLower} style-${styleName}`}>
        <Slider sliderData={contents} />
      </section>
    );
  }
}

export default CmsTemplate;
