import React from 'react';
import Link from '@/components/Link';

import style from './index.less';

class CmsSearch extends React.PureComponent {
  // TODO 加入点击跳转 或者页面直接搜索逻辑
  handleClick = () => {

  }
  render () {
    return (
      <div className="mod-search">
        <Link to="/search">
        <section className="sch-show" onClick={this.handleClick}>请输入关键词</section>
        </Link>
      </div>
    );
  }
}

export default CmsSearch;
