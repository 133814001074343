import { getProdDetail, getProdDetailAgent } from './service'

const Model = {
  namespace: 'detailModel',
  state: {
    data: {

    }
  },
  effects: {
    *getDetail({ payload, callback }, { call, put }) {
      yield put({
        type: 'initProd',
      })
      const api = !!payload.agentId ? getProdDetailAgent : getProdDetail;
      const res = yield call(api, payload);
      if (res.status === 1) {
        yield put({
          type: 'save',
          payload: res.data
        })
      } else {
        alertTip('该商品已抢完');
      }
    },
    *getCmsProd({ payload, callback }, { call, put }) {
      // const res = yield call(getProdList, payload);
      
    },
    *initProd({ payload, callback }, { call, put }) {
      yield put({
        type: 'save',
        payload: {
          
        }
      });
    }
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, data: payload };
    },
  },
};
export default Model;
