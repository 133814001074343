'use extensible';

import React from 'react';
import { cmsLinkTo } from '@/utils/utils';

class SliderItem extends React.PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      firstLoadImg: true,
    };
  }

  componentDidMount () {
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.sliderData !== nextProps.sliderData) {
      this.setState({
        firstLoadImg: true
      });
    }
  }
  handleImgLoad = () => {
    if (this.state.firstLoadImg) {
      window.dispatchEvent(new Event('resize'));
      this.setState({firstLoadImg: false});
    }
  }

  render () {
    const { linkType, objectId, targetUrl, imgUrl } = this.props.data;
    return (
      <div className="carousel-item" onClick={()=> {cmsLinkTo(linkType, objectId, targetUrl)}}>
        <img
          src={imgUrl}
          onLoad={this.handleImgLoad}
        />
      </div>
    )
  }
}

export default SliderItem;