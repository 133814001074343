import React from 'react';
import Carousel from 'nuka-carousel';
import './index.less';

class ImageSlider extends React.PureComponent {
  state = {
    firstLoadImg: true
  };
  componentWillReceiveProps (nextProps) {
    if (this.props.images !== nextProps.images) {
      this.setState({
        firstLoadImg: true
      });
    }
  }
  handleImgLoad = () => {
    if (this.state.firstLoadImg) {
      window.dispatchEvent(new Event('resize'));
      this.setState({firstLoadImg: false});
    }
  }
  render () {
    const { images, imageStyle, height } = this.props;
    return (
      <div className="slider-wrap">
        <Carousel initialSlideHeight={height} dragging={true} wrapAround={true}>
          {
            images.map((i, idx) => {
              return (
                <img
                    key={`slider-${idx}`}
                    src={i} style={imageStyle || void 0}
                    onLoad={this.handleImgLoad}
                />
              )
            })
          }
        </Carousel>
      </div>
    )
  }
}

export default ImageSlider;