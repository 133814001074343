import { getSearch, getPwd } from './service'

const Model = {
  namespace: 'searchModel',
  state: {
    list: [],
    hasMore: true,
    pwd: '',
  },
  effects: {
    *query({ payload, callback }, { call, put, select }) {
      if (payload.page === 0) {
        yield put({
          type: 'init',
        })
      }
      const res = yield call(getSearch, payload);
      if (res.status === 1) {
        const hasMore = res.data.length === 20;
        const history = yield select(state => state.searchModel.list);
        const newList = payload.page === 0 ? res.data : [...history, ...res.data];
        yield put({
          type: 'saveList',
          payload: newList
        })
        yield put({
          type: 'saveHasMore',
          payload: hasMore,
        })
        yield put({
          type: 'savePwd',
          payload: '',
        })
      }
    },
    *init({ callback }, { call, put }) {
      yield put({
        type: 'saveList',
        payload: []
      });
      yield put({
        type: 'saveHasMore',
        payload: true
      });
      yield put({
        type: 'savePwd',
        payload: ''
      });
    },
    *getPwd({ payload, callback }, { call, put }) {
      const res = yield call(getPwd, payload);
      if (res.status === 1) {
        yield put({
          type: 'savePwd',
          payload: res.data.pwd
        });
        if (callback) {
          callback(res.data.pwd);
        }
      }
    },
  },
  reducers: {
    saveList(state, { payload }) {
      return { ...state, list: payload };
    },
    saveHasMore(state, { payload }) {
      return { ...state, hasMore: payload };
    },
    savePwd(state, { payload }) {
      return { ...state, pwd: payload };
    },
  },
};
export default Model;
