import React, { Component } from 'react';
import { connect } from 'dva';
import { Helmet } from 'react-helmet';
import './index.less';
import CmsBanner from './Components/CmsBanner';
import CmsNav from './Components/CmsNav';
import CmsImgGroup from './Components/CmsImgGroup';
import CmsTitleLink from './Components/CmsTitleLink';
import CmsSpacing from './Components/CmsSpacing';
import CmsListPro from './Components/CmsListPro';
import SEARCH from './Components/Search';
import Loading from '@/components/Loading';


const componentMap = {
  BANNER: CmsBanner,
  SEARCH: SEARCH,
  NAV: CmsNav,
  IMG_GROUP: CmsImgGroup,
  TITLE_LINK: CmsTitleLink,
  SPACING: CmsSpacing,
  LIST_PRO: CmsListPro,
};



@connect(({ loading, cmsModel: {
  pageConfig
}}) => ({
  pageConfig,
  loading: loading.effects['cmsModel/getCmsPage'],
}))
class Home extends Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'cmsModel/getCmsPage',
      payload: this.props.location.query.pageId || 449
    })
  }
  render() {
    const {
      pageConfig,
      loading
    } = this.props;
    const {
      moduleList,
      moduleCount,
    } = pageConfig;
    const hasSearch = moduleCount > 0 && !!(moduleList.find(module => module.type === 'SEARCH'));
    const componentNodes = moduleList.map((component) => {
      const { id, type } = component;
      const CmsComponent = componentMap[type] || null;

      if (CmsComponent) {
        return <CmsComponent
         key={id}
         component={component}
        />;
      } else {
        return void 0;
      }
    });
    return (
      <>
        <Helmet>
            <title>{pageConfig.h5title || '有好货'}</title>
        </Helmet>
        {loading ? <Loading /> : ''}
        <div className={"cmsWrap " + ( hasSearch ? 'hasSearch' : '' )}>
          {componentNodes}
        </div>
      </>
    );
  }
}

export default Home;
