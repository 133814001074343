'use extensible';

import React from 'react';

import './index.less';

class CmsSpacing extends React.PureComponent {
  constructor (props) {
    super(props);
  }

  render () {
    const {
      component: {
        type, styleName, 
        jsonContent: { 
          height, color
        }
      }
    } = this.props;

    const typeLower = type.toLowerCase();

    const styleSpacing = {
      height: `${height / 100 }rem`,
      background: color
    };

    return (
      <section className={`cms-panel panel-${typeLower} style-${styleName}`}>
        <section className="mod-spacing" style={styleSpacing}></section>
      </section>
    );
  }
}

export default CmsSpacing;
