'use extensible';

import React from 'react';

import { cmsLinkTo } from '@/utils/utils';
import './index.less';

class CmsImgGroup extends React.PureComponent {
  constructor (props) {
    super(props);
  }

  render () {
    const {
      component: {
        type, styleName, 
        jsonContent: { 
          isShowLine,
          contents
        }
      }
    } = this.props;

    const typeLower = type.toLowerCase();

    return (
      <section className={`cms-panel panel-${typeLower} style-${styleName}`}>
        <ul className={isShowLine ? 'mod-img-group hasline' : 'mod-img-group'}>
          {
            contents.map((item, index)=> {
              const { id, imgUrl, linkType, objectId, targetUrl } = item;
              let className = '';
              if (+styleName === 3) {
                if (index) {
                  className = 'right';
                } else {
                  className = 'left';
                }
              }
              return (
                <li key={id} className={className} onClick={()=> {cmsLinkTo(linkType, objectId, targetUrl)}}>
                  <img src={imgUrl} alt="" />
                </li>
              )
            })
          }
        </ul>
      </section>
    );
  }
}

export default CmsImgGroup;
